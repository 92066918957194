<template>
    <el-dialog title="选择老师" class="choose-teacher" :visible.sync="AssignTeacher" width="740px"
               :close-on-click-modal="false" @opened="openAssign">
        <div class="teacher-content">
            <div class="content-item">
                <div class="content-title">未分配列表</div>
                <el-scrollbar class="class-list" style="height: 100%" :native="false">
                    <el-tree class="tree-level"
                             ref="left_tree"
                             :data="teacherList"
                             :props="defaultProps"
                             show-checkbox
                             node-key="id"
                             default-expand-all
                             :default-checked-keys=defaultCheckedKeys
                             @check-change="handleCheckChange">
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span class="node-avatar">
                                 <img :src="data.head_portrait?data.head_portrait:require('../../assets/img/image/user_img.png')"/>
                            </span>
                            <span class="node-title">{{ node.label }}</span>
                        </span>
                    </el-tree>
                </el-scrollbar>
            </div>
            <div class="content-item content-right">
                <div class="content-title">已分配列表</div>
                <el-scrollbar class="teacher-list" style="height: 100%" :native="false">
                    <div class="teacher-item" v-for="(teacherItem, teacherIndex) in chooseTeachertList"
                         :key="'teacher' + teacherItem.id">
                        <div class="item-left">
                            <span class="node-avatar">
                                <img :src="teacherItem.head_portrait?teacherItem.head_portrait:require('../../assets/img/image/user_img.png')"/>
                            </span>
                            {{teacherItem.name}}
                        </div>
                        <div class="iconfont item-right" @click="delTeacher(teacherItem, teacherIndex)">&#xe8e9;</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="cancelBtn">取 消</el-button>
            <el-button size="medium" type="primary" @click="confirmBtn">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            classId: {
                type: Number
            }
        },
        data() {
            return {
                AssignTeacher: false,
                teacherList: [],
                chooseTeachertList: [],
                defaultProps: {
                    label: 'name',
                },
                defaultCheckedKeys: []
            }
        },
        watch: {
            AssignTeacher(newVal, oldVal) {
                this.teacherList = []
                this.chooseTeachertList = []
                this.defaultCheckedKeys = []
            }
        },
        methods: {
            openAssign() {
                this.getTeacherList()
                // this.getAllocation()
            },
            //获取老师列表
            getTeacherList() {
                this.$http.axiosGetBy(this.$api.getTeacher, {type: 3}, (res) => {
                    if (res.code === 200) {
                        res.data.data.forEach(itemTeacher => {
                            itemTeacher.disabled = false;
                            // itemTeacher.teachers.forEach(itemTeacher => {
                            //     itemTeacher.disabled = false
                            // })
                        })
                        this.teacherList = res.data.data;
                        // 获取已分配老师列表
                        this.$http.axiosGetBy(this.$api.getAllocation, {class_id: this.classId}, (res) => {
                            var dataList = []
                            if (res.code === 200) {
                                this.defaultCheckedKeys = res.data
                                dataList = res.data
                                this.teacherList.forEach((item) => {
                                    dataList.forEach((item1) => {
                                        if (item.id === item1) {
                                            this.chooseTeachertList.push(item)
                                        }
                                    })
                                })
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },

            handleCheckChange() {
                const nodes = this.$refs.left_tree.getCheckedNodes(true);
                this.chooseTeachertList = nodes;
            },
            delTeacher(teacher, index) {
                this.chooseTeachertList.splice(index, 1);
                this.$refs.left_tree.setChecked(teacher.id, false, true)
            },
            cancelBtn() {
                this.AssignTeacher = false
            },
            confirmBtn() {
                let teacherId = [];
                this.chooseTeachertList.forEach(item => {
                    teacherId.push(item.id)
                });
                // teacherId = teacherId.join(',');
                let params = {
                    id: this.classId,
                    teacher_id: teacherId
                };
                //分配老师
                this.$http.axiosPost(this.$api.relTeacher, params, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.message);
                        this.AssignTeacher = false;
                        this.$emit('getClassList');
                        this.getTeacherList()
                    } else {
                        this.$message.warning(res.message);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .choose-teacher {
        ::v-deep .el-dialog__body {
            padding: 0;
            border-top: 2px solid #E4E7ED;
            border-bottom: 2px solid #E4E7ED;

            .teacher-content {
                height: 420px;
                display: flex;

                .content-item {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    &.content-right {
                        border-left: 2px solid #E4E7ED;
                    }

                    .content-title {
                        padding: 20px 20px 0;
                    }

                    .class-list {
                        height: 1%;
                        flex: 1;
                        padding: 10px 0 0;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                    }

                    .teacher-list {
                        height: 1%;
                        flex: 1;
                        padding: 10px 0 0;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }

                        .teacher-item {
                            display: flex;
                            align-items: center;
                            padding: 0 20px;
                            margin-bottom: 5px;

                            &:hover {
                                background-color: rgba(50, 150, 250, .1);
                            }

                            .item-left {
                                width: 1%;
                                flex: 1;
                                line-height: 30px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                align-items: center;
                                display: flex;
                            }

                            .item-right {
                                width: 30px;
                                height: 30px;
                                font-size: 18px;
                                text-align: center;
                                line-height: 30px;
                                cursor: pointer;
                                color: #ccc;
                            }
                        }
                    }
                }
            }
        }
    }

    ::v-deep.tree-level {
        .el-tree-node__content {
            height: 30px;
            padding-left: 20px !important;
            padding-right: 20px;
            margin-bottom: 5px;

            .el-checkbox {
                position: absolute;
                right: 12px;
            }

            .custom-tree-node {
                width: 250px;
                font-size: 0;
                display: flex;
                align-items: center;

                .node-title {
                    font-size: 14px;
                    line-height: 30px;
                    max-width: 250px;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 1%;
                    flex: 1;
                }
            }
        }
    }

    .node-avatar {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        background-size: cover;

        img {
            display: block;
            max-width: 100%;
            height: 20px;
        }
    }
</style>